import {Scheduler} from '@aldabil/react-scheduler'
import React, { useEffect, useState } from 'react';


const Calendar = () => {

    const [dates, setDates] = useState([]);

    useEffect(() => {
       async function fetchDates() {
            const response = await fetch('http://https://joanisketikidis.com/api/zakazhitermin/');
            const data = await response.json();
            setDates(data);
            console.log(data);
        }

        const interval = setInterval(() => {
            fetchDates();
          }, 1000);

          return () => clearInterval(interval);
      }, []);
      

    return (
        <Scheduler
        view="month"
        events={dates.map(item => {
            const {title, event_id, start, end} = item;
            return ({
                event_id: event_id,
                title: title,
                start: new Date(start),
                end:   new Date(end),
            })
        })}
        />
    )
}

export default Calendar;