import Calendar from './calendar/Calendar';
import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Choser from './calendar/Choser';
import { react } from '@babel/types';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Choser />} />
      <Route path="kalendar" element={<Calendar />} />
    </Routes>
  </Router>
  );
}

export default App;
