import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper'
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button'
import '.././App.css'
import { styled } from '@mui/material/styles';
import Handyman from '@mui/icons-material/Handyman';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Item from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import {
  ArrowBack,
  ArrowForward,
  AssignmentInd,
  CalendarMonthOutlined,
  Done,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Fab } from '@mui/material';
import { FormControl, Grid, ListItem, ListItemText } from '@mui/material';
import { Checkbox } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import tireAnimation from "../download.gif"
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { mk } from "date-fns/locale";
import { DateAdapter as CustomAdapter } from './custom-calendar'

const steps = [
  '',
  '',
  '',
  '',
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Handyman />,
    2: <CalendarMonthOutlined />,
    3: <AssignmentInd />,
    4: <ReceiptLongIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#395177"),
}));

const Choser = () => {
  const [stepper, setStepper] = useState(0)
  const [value, setValue] = useState(null)
  const [time, setTime] = useState("")
  const [personalInfo, setPersonalInfo] = useState({ name: "", description: "", lastName: "", phone: "", email: "", registration: "", keepTires: false, policyAccept: false })
  const [description, setDescription] = useState("")
  const [fetchData, setFetchData] = useState([])
  const [fetchPrednica, setFetchPrednica] = useState([])
  const [load, setLoad] = useState(true)
  const [alertMessage, setAlertMessage] = useState("")
  const [isAlertVisible, setIsAlertVisible] = useState(false)

  const setPlan = (selectedPlan) => {
    setDescription(selectedPlan);
  }
  const setTimeFunction = (param) => {
    setTime(param);
    goNext();
  }

  const listNames = [
    {
      label: "Име",
      data: personalInfo.name
    },
    {
      label: "Презиме",
      data: personalInfo.lastName
    },
    {
      label: "Телефонски Број",
      data: personalInfo.phone
    },
    {
      label: "Коментар",
      data: personalInfo.description,
      ok: true
    },
    {
      label: "Електронска Адреса",
      data: personalInfo.email
    },
    {
      label: "Датум",
      data: value
    },
    {
      label: "Време",
      data: time
    },
    {
      label: "Услуга",
      data: description
    },
    {
      label: "Регистрација",
      data: personalInfo.registration
    },
    {
      label: "Чувам гуми?",
      data: personalInfo.keepTires ? "Да" : "Не"
    },
  ]

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const getData = async (e) => {
    try {
      const response = await fetch("https://joanisketikidis.com/api/zakazhitermin/", {
        method: "GET",
      });
      console.log(response)
      const data = await response.json();
      setFetchData(data);
    } catch (err) {
      console.log(err);
    }
  }

  const getPrednicaData = async (e) => {
    try {
      const response = await fetch("https://joanisketikidis.com/api/zakazhiprednica/", {
        method: "GET",
      });
      const data = await response.json();
      setFetchPrednica(data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      let filteredDescription = Object.fromEntries(Object.entries(personalInfo).filter(([key]) => !key.includes('description')));
      let has = Object.values(filteredDescription).filter(item => item === "")

      if (!personalInfo.policyAccept || !description || has.length > 0) {
        console.log("Not all required")
        return
      }

      const year = value.getFullYear();
      const month = value.getMonth() + 1;
      const day = value.getDate();
      
      const body = { name: personalInfo.name, lastName: personalInfo.lastName, phone: personalInfo.phone, email: personalInfo.email, date: `${day}/${month}/${year}`, hour: time, description, comment: personalInfo.description, registration: personalInfo.registration, chuvagumi: personalInfo.keepTires };
      const response = await fetch("https://joanisketikidis.com/api/zakazhitermin/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const data = await response.json()
      console.log(data)
      if (data.message === 'Created Successfully!') {
        setStepper(4)
      } else if (data.message === 'Someone already reserved!') {
        callAlert('Некој резервираше истовреме, одберете друг термин!')
      } else {
        callAlert('Настана грешка, обидете се повторно или јавете се')
      }
    } catch (err) {
      console.log(err);
      callAlert('Настана грешка, обидете се повторно или јавете се')
    }
  }

  const callAlert = (message) => {
    setIsAlertVisible(true)
    setAlertMessage(message)
    setTimeout(() => {
      setIsAlertVisible(false)
      setAlertMessage("")
    }, 5000)
  }

  const handlePrednicaSubmit = async (e) => {
    // e.preventDefault();
    try {
      let filteredDescription = Object.fromEntries(Object.entries(personalInfo).filter(([key]) => !key.includes('description')));
      let has = Object.values(filteredDescription).filter(item => item === "")

      if (!personalInfo.policyAccept || !description || has.length > 0) {
        console.log("Not all required")
        return
      }

      const year = value.getFullYear();
      const month = value.getMonth() + 1;
      const day = value.getDate();

      const body = { name: personalInfo.name, lastName: personalInfo.lastName, phone: personalInfo.phone, email: personalInfo.email, date: `${day}/${month}/${year}`, hour: time, description, comment: personalInfo.description, registration: personalInfo.registration, chuvagumi: personalInfo.keepTires };
      const response = await fetch("https://joanisketikidis.com/api/zakazhiprednica/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.message === 'Created Successfully!') {
        setStepper(4)
      } else if (data.message === 'Someone already reserved!') {
        callAlert('Некој резервираше истовреме, одберете друг термин!')
      } else {
        callAlert('Настана грешка, обидете се повторно или јавете се')
      }
    } catch (err) {
      console.log(err);
      callAlert('Настана грешка, обидете се повторно или јавете се')
    }
  }

  const dateConvert = (year) => {
    year.setFullYear(year.getFullYear() + 1);
    return new Date(year.toDateString());

  }

  const latinToCyrillic = (word) => {
    let a = { "A": "А", "B": "Б", "V": "В", "G": "Г", "D": "Д", "GJ": "Ѓ", "E": "Е", "ZH": "Ж", "Z": "З", "DZ": "S", "I": "И", "K": "К", "L": "Л", "LJ": "Љ", "M": "М", "N": "Н", "NJ": "Њ", "O": "О", "P": "П", "R": "Р", "S": "С", "T": "Т", "KJ": "Ќ", "U": "У", "F": "Ф", "H": "Х", "C": "Ц", "CH": "Ч", "DZH": "Џ", "SH": "Ш" }

    return word.split('').map(function (char) {
      return a[char] || char;
    }).join("");
  }

  const goBack = () => {
    setStepper(stepper - 1);
  }

  const goNext = () => {
    setStepper(stepper + 1);
  }

  const disableHolidays = [
    "Wed May 01 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Sat May 04 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Sun May 05 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Mon May 06 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Wed May 08 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Fri May 24 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Fri Jun 21 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Fri Aug 02 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Sun Sep 08 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Mon Sep 09 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Fri Oct 11 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Wed Oct 23 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Wed Apr 24 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Sun Oct 27 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Thu Oct 31 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Sun Dec 08 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Mon Dec 09 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Tue Dec 24 2024 00:00:00 GMT+0200 (Central European Summer Time)",
    "Tue Dec 31 2024 00:00:00 GMT+0200 (Central European Summer Time)",
  ]

  function disableWeekends(date) {
    setTimeout(() => {
      var elems = document.querySelectorAll('[disabled]'); // target all disabled elemnts
      for (var i = 0; i < elems.length; i++) {
        if (!elems[i].classList.contains('calendar-time-date')) {
          elems[i].className = "lol"
        }
      }
      disableHolidays.forEach((item) => {
        const element = document.querySelector(`[aria-label="${item}"]`);
        if (element) {
          element.className = "lol";
          element.setAttribute("disabled", "true")
        }
      })
    }, 0)
    return date.getDay() === 0 || disableHolidays.includes(date.toString())
  }

  const checkAvailability = (param) => {
    if (param && value) {
      const year = value.getFullYear();
      const month = value.getMonth() + 1;
      const day = value.getDate();
      for (let i = 0; i < fetchData.length; i++) {
        if (fetchData[i].hour == param && (fetchData[i].date == `${day}/${month}/${year}` || new Date(fetchData[i].date).toLocaleString().slice(0, 10) == value.toLocaleString().slice(0, 10))) {
          return true
        }
      }
    }
  }

  const checkPrednicaAvailability = (param) => {
    if (param && value) {
      const year = value.getFullYear();
      const month = value.getMonth() + 1;
      const day = value.getDate();
      for (let i = 0; i < fetchPrednica.length; i++) {
        if (fetchPrednica[i].hour == param && (fetchPrednica[i].date == `${day}/${month}/${year}` || new Date(fetchData[i].date).toLocaleString().slice(0, 10) == value.toLocaleString().slice(0, 10))) {
          return true
        }
      }
    }
  }

  useEffect(() => {
    getData();
    getPrednicaData()
    setTimeout(() => {
      setLoad(false)
    }, 2000)
  }, [])

  return (
    <div className="center-screen">
      {load && <img alt='tire-animation' src={tireAnimation} />}
      {!load && <Paper variant="outlined" className='card-style'>
        <Container className="evropa-bg">
          <a href="https://evropagumi.com.mk/">
            <img style={{ padding: "12px" }} src="https://evropagumi.com.mk/skin/frontend/smartwave/porto/images/logo_trans_newcolor.png" alt='logogumi'></img>
          </a>
        </Container>
        <Box className='box-style'>
          <Stepper alternativeLabel activeStep={stepper} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {stepper === 0 &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <Typography variant='h5' className='small-heading-font' style={{ color: '#395177', fontWeight: 'bold', marginTop: "32px" }}>
                Изберете тип на сервис
              </Typography>
              <hr />
              <Stack spacing={2} style={{ marginTop: "18px" }}>
                <Item><ColorButton variant="contained" disabled={description === 'Промена на Гуми'} className='button-style' onClick={() => { setPlan('Промена на Гуми'); goNext() }}>
                  <Typography className="typo-text">
                    Промена на гуми
                  </Typography>
                  <Typography variant="subtitle1" className="sub-text small-heading-font">
                    Резервирајте термин за промена на гуми на вашето возило
                  </Typography>
                </ColorButton></Item>
                <Item><ColorButton variant="contained" disabled={description === 'Промена на масло/сопирачки'} className='button-style' onClick={() => window.location.href = "https://evropagumi.com.mk/formular"}>
                  <Typography className="typo-text">
                    Промена на масло/сопирачки
                  </Typography>
                  <Typography variant="subtitle1" className="sub-text small-heading-font">
                    Побарајте понуда за промена на масло или сопирачки
                  </Typography>
                </ColorButton></Item>
                <Item><ColorButton variant="contained" disabled={description === 'Центрирање на предница'} className='button-style' onClick={() => { setPlan('Центрирање на Предница'); goNext() }}>
                  <Typography className="typo-text">
                    Центрирање на предница
                  </Typography>
                  <Typography variant="subtitle1" className="sub-text small-heading-font">
                    Закажете термин за центрирање на предница на вашето возило
                  </Typography>
                </ColorButton></Item>
              </Stack><br />
            </Box>
          </Container>}

        {stepper === 1 && description === "Промена на Гуми" &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <Typography className="izberete-datum-i-vreme" variant='h5'>
                Изберете датум и време
              </Typography>
              <hr className="hide" />

              <LocalizationProvider adapterLocale={mk} dateAdapter={CustomAdapter}>
                <CalendarPicker minDate={new Date().setDate(new Date().getDate() + 1)} maxDate={dateConvert(new Date())}
                  date={value} shouldDisableDate={disableWeekends}
                  onChange={(newDate) => {
                    setValue(newDate);
                  }}

                />
              </LocalizationProvider>


              <Grid container rowSpacing={'auto'} columns={'auto'} my={"2rem"} className="realign-media">
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={3} md={1}><Tooltip title="08:00-09:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '08:00-09:00' ? '3px solid red' : 'transparent', color: time === '08:00-09:00' ? 'white' : '', cursor: checkAvailability('08:00-09:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('08:00-09:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('08:00-09:00')} onClick={() => setTimeFunction("08:00-09:00")}>08:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="09:00-10:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '09:00-10:00' ? '3px solid red' : 'transparent', color: time === '09:00-10:00' ? 'white' : '', cursor: checkAvailability('09:00-10:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('09:00-10:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('09:00-10:00')} onClick={() => setTimeFunction("09:00-10:00")}>09:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="10:00-11:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '10:00-11:00' ? '3px solid red' : 'transparent', color: time === '10:00-11:00' ? 'white' : '', cursor: checkAvailability('10:00-11:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('10:00-11:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('10:00-11:00')} onClick={() => setTimeFunction("10:00-11:00")}>10:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="11:00-12:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '11:00-12:00' ? '3px solid red' : 'transparent', color: time === '11:00-12:00' ? 'white' : '', cursor: checkAvailability('11:00-12:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('11:00-12:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('11:00-12:00')} onClick={() => setTimeFunction("11:00-12:00")}>11:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="12:00-13:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '12:00-13:00' ? '3px solid red' : 'transparent', color: time === '12:00-13:00' ? 'white' : '', cursor: checkAvailability('12:00-13:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('12:00-13:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('12:00-13:00')} onClick={() => setTimeFunction("12:00-13:00")}>12:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="13:00-14:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '13:00-14:00' ? '3px solid red' : 'transparent', color: time === '13:00-14:00' ? 'white' : '', cursor: checkAvailability('13:00-14:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('13:00-14:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('13:00-14:00')} onClick={() => setTimeFunction("13:00-14:00")}>13:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="14:00-15:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '14:00-15:00' ? '3px solid red' : 'transparent', color: time === '14:00-15:00' ? 'white' : '', cursor: checkAvailability('14:00-15:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('14:00-15:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('14:00-15:00')} onClick={() => setTimeFunction("14:00-15:00")}>14:00</Button></Tooltip></Grid>
                {new Date(value).getDay() !== 6 && <Grid item xs={3} md={1}><Tooltip title="15:00-16:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '15:00-16:00' ? '3px solid red' : 'transparent', color: time === '15:00-16:00' ? 'white' : '', cursor: checkAvailability('15:00-16:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('15:00-16:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('15:00-16:00')} onClick={() => setTimeFunction("15:00-16:00")}>15:00</Button></Tooltip></Grid>}
                {new Date(value).getDay() !== 6 && <Grid item xs={3} md={1}><Tooltip title="16:00-17:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '16:00-17:00' ? '3px solid red' : 'transparent', color: time === '16:00-17:00' ? 'white' : '', cursor: checkAvailability('16:00-17:00') ? "not-allowed" : "pointer", backgroundColor: checkAvailability('16:00-17:00') ? "#ff00007d" : "#00800063" }} disabled={checkAvailability('16:00-17:00')} onClick={() => setTimeFunction("16:00-17:00")}>16:00</Button></Tooltip></Grid>}
              </Grid>

            </Box>
          </Container>
        }

        {stepper === 1 && description === "Центрирање на Предница" &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <Typography className="izberete-datum-i-vreme" variant='h5'>
                Изберете датум и време
              </Typography>
              <hr className="hide" />

              <LocalizationProvider locale={mk} dateAdapter={CustomAdapter}>
                <CalendarPicker minDate={new Date().setDate(new Date().getDate() + 1)} maxDate={dateConvert(new Date())} date={value} shouldDisableDate={disableWeekends}
                  onChange={(newDate) => { setValue(newDate); }}
                />
              </LocalizationProvider>


              <Grid container rowSpacing={'auto'} columns={'auto'} my={"2rem"} className="realign-media">
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={3} md={1}><Tooltip title="08:00-09:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '08:00-09:00' ? '3px solid red' : 'transparent', color: time === '08:00-09:00' ? 'white' : '', cursor: checkPrednicaAvailability('08:00-09:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('08:00-09:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('08:00-09:00')} onClick={() => setTimeFunction("08:00-09:00")}>08:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="09:00-10:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '09:00-10:00' ? '3px solid red' : 'transparent', color: time === '09:00-10:00' ? 'white' : '', cursor: checkPrednicaAvailability('09:00-10:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('09:00-10:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('09:00-10:00')} onClick={() => setTimeFunction("09:00-10:00")}>09:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="10:00-11:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '10:00-11:00' ? '3px solid red' : 'transparent', color: time === '10:00-11:00' ? 'white' : '', cursor: checkPrednicaAvailability('10:00-11:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('10:00-11:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('10:00-11:00')} onClick={() => setTimeFunction("10:00-11:00")}>10:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="11:00-12:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '11:00-12:00' ? '3px solid red' : 'transparent', color: time === '11:00-12:00' ? 'white' : '', cursor: checkPrednicaAvailability('11:00-12:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('11:00-12:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('11:00-12:00')} onClick={() => setTimeFunction("11:00-12:00")}>11:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="12:00-13:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '12:00-13:00' ? '3px solid red' : 'transparent', color: time === '12:00-13:00' ? 'white' : '', cursor: checkPrednicaAvailability('12:00-13:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('12:00-13:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('12:00-13:00')} onClick={() => setTimeFunction("12:00-13:00")}>12:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="13:00-14:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '13:00-14:00' ? '3px solid red' : 'transparent', color: time === '13:00-14:00' ? 'white' : '', cursor: checkPrednicaAvailability('13:00-14:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('13:00-14:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('13:00-14:00')} onClick={() => setTimeFunction("13:00-14:00")}>13:00</Button></Tooltip></Grid>
                <Grid item xs={3} md={1}><Tooltip title="14:00-15:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '14:00-15:00' ? '3px solid red' : 'transparent', color: time === '14:00-15:00' ? 'white' : '', cursor: checkPrednicaAvailability('14:00-15:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('14:00-15:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('14:00-15:00')} onClick={() => setTimeFunction("14:00-15:00")}>14:00</Button></Tooltip></Grid>
                {new Date(value).getDay() !== 6 && <Grid item xs={3} md={1}><Tooltip title="15:00-16:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '15:00-16:00' ? '3px solid red' : 'transparent', color: time === '15:00-16:00' ? 'white' : '', cursor: checkPrednicaAvailability('15:00-16:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('15:00-16:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('15:00-16:00')} onClick={() => setTimeFunction("15:00-16:00")}>15:00</Button></Tooltip></Grid>}
                {new Date(value).getDay() !== 6 && <Grid item xs={3} md={1}><Tooltip title="16:00-17:00" placement="top"><Button className="calendar-time-date" style={{ border: time === '16:00-17:00' ? '3px solid red' : 'transparent', color: time === '16:00-17:00' ? 'white' : '', cursor: checkPrednicaAvailability('16:00-17:00') ? "not-allowed" : "pointer", backgroundColor: checkPrednicaAvailability('16:00-17:00') ? "#ff00007d" : "#00800063" }} disabled={checkPrednicaAvailability('16:00-17:00')} onClick={() => setTimeFunction("16:00-17:00")}>16:00</Button></Tooltip></Grid>}
              </Grid>

            </Box>
          </Container>
        }

        {stepper === 2 &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <FormControl sx={{ width: '100%' }}>
                <Stack spacing={2} sx={{ width: 'inherit' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                  <TextField
                    id="outlined-helperText"
                    label="Име"
                    name="name"
                    value={personalInfo.name}
                    onChange={handleChange}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Презиме"
                    name="lastName"
                    value={personalInfo.lastName}
                    onChange={handleChange}
                  />
                </Stack>
                <br />
                <Stack spacing={2} sx={{ width: 'inherit' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                  <TextField
                    id="outlined-helperText"
                    label="Телефонски Број"
                    name="phone"
                    value={personalInfo.phone}
                    onChange={handleChange}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Е-маил адреса"
                    name="email"
                    value={personalInfo.email}
                    onChange={handleChange}
                  />
                </Stack>
                <br />
                <TextField
                  id="outlined-helperText"
                  label="Регистрација"
                  name="registration"
                  placeholder="SK1234BH"
                  value={personalInfo.registration}
                  onChange={handleChange}
                />
                <br />
                <TextField
                  placeholder="Внесете коментар"
                  multiline
                  rows={3}
                  name="description"
                  maxRows={3}
                  value={personalInfo.description}
                  onChange={handleChange}
                />
                {description !== "Центрирање на Предница" && <div class='checkbox'>
                  <Checkbox style={{ padding: '10px 0' }} size='large' checked={personalInfo.keepTires} onClick={(e) => setPersonalInfo({ ...personalInfo, keepTires: e.target.checked })} color="default" />
                  <span style={{ marginLeft: "5px" }}>Чувам Гуми во Европа Гуми</span>
                </div>}
              </FormControl>
            </Box>
          </Container>
        }

        {stepper === 3 &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <Grid container className='info-page'>
                {listNames.map((item) => {
                  if (description === "Центрирање на Предница" && item.label === "Чувам гуми?") {
                    return
                  }
                  return (
                    <Grid item xs={6}>
                      <Item>
                        <ListItem>
                          <ListItemText
                            primary={item.label}
                            className={item.ok || item.data ? "" : "secondary-item"}
                            secondary={item.data ? (item.label === 'Датум' ? new Date(item.data).toLocaleString().slice(0, 10).toString() : item.data) : (item.ok ? "Не задолжително поле" : "Немате Посочено!")}
                          />
                        </ListItem>
                      </Item>
                    </Grid>
                  )
                })}
              </Grid>
              <Divider variant="middle" />
              <Stack p={"7px"} direction={"row"} alignItems="center">
                <Checkbox size='large' checked={personalInfo.policyAccept} onClick={(e) => setPersonalInfo({ ...personalInfo, policyAccept: e.target.checked })} color="default" />
                <Typography className='se-soglasuvam-c' align={"left"}>Се согласувам моите лични податоци да бидат користени за реализација на резервацијата. Политика на приватност.</Typography>
              </Stack>
            </Box>
          </Container>
        }

        {stepper == 4 &&
          <Container>
            <Box sx={{ width: '100%' }} className='box-style'>
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <Typography align={"center"}>Почитуван/а <b>{latinToCyrillic(personalInfo.name.toUpperCase())}</b> вашата резервација за <b>{description.toLowerCase()}</b> на ден <b>{value.toLocaleString("en-GB").slice(0, 10)}</b> во <b>{time.slice(0, 5)}</b> часот е успешно резервирана. </Typography>
              <Typography>Ве замолуваме да пристигнете <span style={{ color: 'red' }}>15 минути</span> пред закажаниот термин.</Typography>
            </Box>
          </Container>
        }

        <Container>
          <Stack spacing={2} justifyContent='center' direction="row" my={"15px"}>
            {stepper > 0 && stepper < 4 && <Fab variant="extended" onClick={goBack} style={{ background: 'rgb(233,64,87)', padding: "6px 16px" }} size="small" color="primary">
              <ArrowBack />
              Назад
            </Fab>
            }
            {stepper !== 3 && stepper !== 4 && <Fab variant="extended" onClick={() => goNext()} style={{ background: 'rgb(233,64,87)', padding: "6px 16px" }} size="small" color="primary">
              Следно
              <ArrowForward />
            </Fab>}
            {stepper === 3 && <Fab variant="extended" onClick={() => description === "Центрирање на Предница" ? handlePrednicaSubmit() : handleSubmit()} style={{ background: 'rgb(233,64,87)', padding: "6px 16px" }} size="small" color="primary">
              Резервирај
              <ArrowForward />
            </Fab>}
            {stepper === 4 && <Button onClick={() => window.location.href = 'https://evropagumi.com.mk/'} color="success" variant="contained" endIcon={<Done />}>
              Во ред
            </Button>}
          </Stack>
        </Container>
      </Paper>}
      {isAlertVisible && <div style={{
        position: "fixed",
        top: 0,
        backgroundColor: "#00000045",
        left: 0,
        right: 0,
        bottom: 0,
        width: "100",
        zIndex: 10000,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Alert severity="error">
          {alertMessage}
        </Alert></div>}

    </div>
  );
}

export default Choser;