import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
class CustomString extends String {
  charAt(_) {
    return this.valueOf();
  }
}

const weekDays = ['пон', 'вто', 'сре', 'чет', 'пет', 'саб', 'нед'];
const customWeekDays = weekDays.map((day) => new CustomString(day));

export class DateAdapter extends AdapterDateFns {
  getWeekdays = () => customWeekDays;
}